import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
	transform = (value: string, maxLength: number, seeFullContent: boolean) => {
		if (!seeFullContent) {
			return value.length > maxLength ? value.substring(0, maxLength) : value;
		}
		return value;
	}
}