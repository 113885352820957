import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ShrService } from 'src/app/modules/core/services/shr.service';

import * as ConstructionProgressActions from 'src/app/modules/ngrx-store/construction-progress/construction-progress.actions';
import * as YourHomeActions from 'src/app/modules/ngrx-store/your-home/your-home.actions';

import { displayError } from 'src/app/modules/shared/utilities/notifications.utils';

@Injectable()
export class ConstructionProgressEffects {

	constructor(
		private actions$: Actions,
		private shrService: ShrService
	) { }

	// Retrieves the Construction Updates on the Construction Progress page.
	loadConstructionUpdates$ = createEffect(() => this.actions$.pipe(
		ofType(YourHomeActions.loadHomeDetailsSuccess),
		switchMap(({ homeDetails }) =>
			this.shrService.getConstructionUpdates(homeDetails.salesAgreementId).pipe(
				map((constructionUpdates) => ConstructionProgressActions.loadConstructionUpdatesSuccess({ constructionUpdates })),
				catchError((err) =>
					of(
						ConstructionProgressActions.loadConstructionUpdatesFailure({
							error: err
						})
					))
			)
		)
	));

	// When loading Construction Updates fails, display an alert notifying the user of the error
	loadConstructionUpdatesFailure$ = createEffect(() => this.actions$.pipe(
		ofType(ConstructionProgressActions.loadConstructionUpdatesFailure),
		tap(err => {
			displayError(err?.error);
		})
	),
	{ dispatch: false }
	);
}