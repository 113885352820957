/* The Shared Module is for anything shared throughout the entire application.
This includes Components, directives, guards, & pipes. Also is where most Angular Material
modules should be imported because the other modules will likely import the shared
module, and in turn be able to utilize those components. */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CloudinaryModule } from '@cloudinary/ng';

import { CloudinaryMediaComponent } from 'src/app/modules/shared/components/cloudinary-media/cloudinary-media.component';
import { CtaCardComponent } from 'src/app/modules/shared/components/cta-card/cta-card.component';
import { MediaGridComponent } from 'src/app/modules/shared/components/media-grid/media-grid.component';
import { ScarProgressBarComponent } from 'src/app/modules/shared/components/scar-progress-bar/scar-progress-bar.component';
import { ScarProgressIconComponent } from 'src/app/modules/shared/components/scar-progress-icon/scar-progress-icon.component';
import { TextCardComponent } from 'src/app/modules/shared/components/text-card/text-card.component';
import { IfViewSizeDirective } from 'src/app/modules/shared/directives/if-view-size.directive';
import { HandingLabelPipe } from 'src/app/modules/shared/pipes/handing.pipe';
import { EllipsisPipe } from 'src/app/modules/shared/pipes/ellipsis.pipe';
import { InternalUrlPipe } from 'src/app/modules/shared/pipes/internal-url.pipe';

@NgModule({
	declarations: [
		CloudinaryMediaComponent,
		CtaCardComponent,
		EllipsisPipe,
		HandingLabelPipe,
		IfViewSizeDirective,
		InternalUrlPipe,
		MediaGridComponent,
		ScarProgressBarComponent,
		ScarProgressIconComponent,
		TextCardComponent
	],
	imports: [
		CloudinaryModule,
		CommonModule,
		MatCardModule,
		MatIconModule,
		MatButtonModule,
		RouterModule,
	],
	exports: [
		CommonModule,
		CloudinaryMediaComponent,
		CtaCardComponent,
		EllipsisPipe,
		FormsModule,
		HandingLabelPipe,
		IfViewSizeDirective,
		InternalUrlPipe,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatOptionModule,
		MatRadioModule,
		MatSelectModule,
		MatSidenavModule,
		MatSlideToggleModule,
		MatTableModule,
		MatTabsModule,
		MatTooltipModule,
		MediaGridComponent,
		ReactiveFormsModule,
		ScarProgressBarComponent,
		ScarProgressIconComponent,
		TextCardComponent
	],
	providers: [
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
	]
})
export class SharedModule { }
