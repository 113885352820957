<mat-sidenav-container autosize *ngIf="(homeDetailsPending$ | async) === false && !!(homeDetails$ | async)">
	<mat-sidenav #sidenav mode="over">
		<div *cpIfViewSize="['tablet', 'mobile']" class="cp-flex-container">
			<div class="cp-flex-col">
				<div class="cp-flex-row cp-icon">
					<button mat-icon-button aria-label="close-sidenav" (click)="sidenav.close()" disableRipple>
						<mat-icon fontSet="material-symbols-outlined">close</mat-icon>
					</button>
				</div>
				<div class="cp-flex-row cp-customer-name">
					<span data-testid="customer-name">Welcome, {{ (userInfo$ | async)?.firstName }}</span>
				</div>
				<div class="cp-flex-row cp-address">
					<span>{{(homeDetails$ | async)?.lot?.streetAddress1?.trim()}},{{(homeDetails$ | async)?.lot?.streetAddress2?.trim() ? ' ' + (homeDetails$ | async)?.lot?.streetAddress2?.trim() + ',' : ''}}</span>
					<span>{{(homeDetails$ | async)?.lot?.city?.trim()}}, {{(homeDetails$ | async)?.lot?.stateProvince?.trim()}} {{(homeDetails$ | async)?.lot?.postalCode?.trim()}}</span>
				</div>
				<div class="cp-flex-row cp-my-account">
					<a mat-button [href]="myAccountUrl" target="_blank" disableRipple>
						My Account
						<mat-icon fontSet="material-symbols-outlined" iconPositionEnd>open_in_new</mat-icon>
					</a>
				</div>
			</div>
		</div>
	</mat-sidenav>
	<mat-sidenav-content>
		<cp-header class="cp-main-header" (hamburgerClicked)="sidenav.toggle()"></cp-header>
		<!-- The scroll action is added here for desktop due to the scrolling action being handled inside the cp-nav-container container -->
		<div class="cp-nav-container" (scroll)="scrollService.handleScroll($event)" #cpNavContainer>
			<div class="cp-sidenav-content">
				<cp-navigation></cp-navigation>
			</div>
			<!-- The scroll action is added here for mobile due to the scrolling action being handled inside the cp-main-contnent container -->
			<div class="cp-main-content" (scroll)="scrollService.handleScroll($event)" #cpMainContent>
				<router-outlet></router-outlet>
				<cp-footer></cp-footer>
			</div>
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>
