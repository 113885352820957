import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';

import * as fromRoot from 'src/app/modules/ngrx-store/reducers';
import * as ConstructionProgressActions from 'src/app/modules/ngrx-store/construction-progress/construction-progress.actions';
import * as ConstructionProgressSelectors from 'src/app/modules/ngrx-store/construction-progress/construction-progress.selectors';

@Injectable()
export class ConstructionProgressFacade {
	constructionUpdates$ = this.store.pipe(select(ConstructionProgressSelectors.getConstructionUpdates));
	constructionUpdatesFailure$ = this.store.pipe(select(ConstructionProgressSelectors.getConstructionUpdatesFailure));
	constructionUpdatesPending$ = this.store.pipe(select(ConstructionProgressSelectors.getConstructionUpdatesPending));
	constructionUpdatesSuccess$ = this.store.pipe(select(ConstructionProgressSelectors.getConstructionUpdatesSuccess));

	constructor(protected store: Store<fromRoot.AppDomainPartialState>) { }

	loadConstructionUpdates(salesAgreementId: number) {
		this.store.dispatch(ConstructionProgressActions.loadConstructionUpdates({ salesAgreementId }));
	}
}