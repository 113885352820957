import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';

import * as fromRoot from 'src/app/modules/ngrx-store/reducers';
import * as YourHomeActions from 'src/app/modules/ngrx-store/your-home/your-home.actions';
import * as YourHomeSelectors from 'src/app/modules/ngrx-store/your-home/your-home.selectors';

@Injectable()
export class YourHomeFacade {
	homeDetails$ = this.store.pipe(select(YourHomeSelectors.getHomeDetails));
	homeDetailsFailure$ = this.store.pipe(select(YourHomeSelectors.getHomeDetailsFailure));
	homeDetailsPending$ = this.store.pipe(select(YourHomeSelectors.getHomeDetailsPending));
	jobOptions$ = this.store.pipe(select(YourHomeSelectors.getJobOptions));
	jobOptionsPending$ = this.store.pipe(select(YourHomeSelectors.getJobOptionsPending));
	salesAgreementId$ = this.store.pipe(select(YourHomeSelectors.getSalesAgreementId));
	isPhdLite$ = this.store.pipe(select(YourHomeSelectors.getIsPhdLite));
	isHomeSelections$ = this.store.pipe(select(YourHomeSelectors.getIsHomeSelections));
	planSalesName$ = this.store.pipe(select(YourHomeSelectors.getPlanSalesName));
	financialPlanIntegrationKey$ = this.store.pipe(select(YourHomeSelectors.getFinancialPlanIntegrationKey));
	floorPlanAttachments$ = this.store.pipe(select(YourHomeSelectors.getFloorPlanAttachments));
	isDesignPreviewEnabled$ = this.store.pipe(select(YourHomeSelectors.getIsDesignPreviewEnabled));
	closeOfEscrow$ = this.store.pipe(select(YourHomeSelectors.getCloseOfEscrow));

	constructor(protected store: Store<fromRoot.AppDomainPartialState>) { }

	loadHomeDetails(salesAgreementId?: number) {
		this.store.dispatch(YourHomeActions.loadHomeDetails({ salesAgreementId }));
	}
}