import { createAction, props } from '@ngrx/store';

import { ConstructionUpdate } from 'src/app/modules/core/models/shr.model';

const source = '[ConstructionProgress]';

/*
 * loadConstructionUpdates
 */
export const loadConstructionUpdates = createAction(
	`${source} loadConstructionUpdates`,
	props<{ salesAgreementId: number }>()
);

export const loadConstructionUpdatesFailure = createAction(
	`${source} loadConstructionUpdatesFailure`,
	props<{ error: Error }>()
);

export const loadConstructionUpdatesSuccess = createAction(
	`${source} loadConstructionUpdatesSuccess`,
	props<{ constructionUpdates: ConstructionUpdate[] }>()
);