import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationInsights, ICustomProperties, IEventTelemetry, IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { AngularPlugin, ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

import { IEnvironment } from 'src/environments/environment.model';

import { UserInfo } from 'src/app/modules/shared/types/auth';

import { CORE_ROUTES } from 'src/app/modules/shared/constants/route-paths';

@Injectable()
export class ApplicationInsightsService {
	// Angular App Insights examples here: https://github.com/microsoft/applicationinsights-angularplugin-js
	myinjector = Injector.create({
		providers: [
			{ provide: ApplicationinsightsAngularpluginErrorService, useClass: ApplicationinsightsAngularpluginErrorService }
		]
	});

	private angularPlugin = new AngularPlugin(this.myinjector);
	private clickPluginInstance = new ClickAnalyticsPlugin();
	private clickPluginConfig = {
		autoCapture: true,
		useDefaultContentNameOrId: true
	};

	private appInsights?: ApplicationInsights;

	constructor(private router: Router) {}

	initializeTelemetry(brandName: string, salesAgreementNumber: string, settings: IEnvironment, userInfo: UserInfo) {
		if (settings.envName != 'local') {
			this.appInsights = new ApplicationInsights({
				config: {
					autoTrackPageVisitTime: true,
					connectionString: settings.applicationInsights.connectionString,
					// Disables remote dependency logging
					disableAjaxTracking: true,
					enableAutoRouteTracking: true,
					extensions: [this.angularPlugin, this.clickPluginInstance],
					extensionConfig: {
						[this.angularPlugin.identifier]: {
							router: this.router, useInjector: true
						},
						[this.clickPluginInstance.identifier]: this.clickPluginConfig
					},
				},
			});
	
			this.loadAppInsights(brandName, salesAgreementNumber, userInfo.userName, userInfo.accountId);
		}
	}

	// This method was created so that this service could be tested without
	// spying on the Application Insights package
	loadAppInsights(brandName: string, salesAgreementNumber: string, userName: string, accountId: string) {
		this.appInsights?.loadAppInsights();
		this.appInsights?.setAuthenticatedUserContext(userName, accountId, true);
		this.appInsights?.addTelemetryInitializer(envelope => {
			envelope.data = {
				isPreview: window.location.pathname.includes(CORE_ROUTES.Preview),
				currentPage: window.location.pathname.split('/').at(-1),
				brandName: brandName,
				salesAgreementNumber: salesAgreementNumber
			}
		});
	}

	// expose tracking methods 
	trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
		this.appInsights?.trackEvent(event, customProperties);
	}

	trackPageView(pageView?: IPageViewTelemetry) {
		this.appInsights?.trackPageView(pageView);
	}
}
