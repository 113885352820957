import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as ServiceRequestActions from 'src/app/modules/ngrx-store/service-requests/service-requests.actions';
import * as YourHomeActions from 'src/app/modules/ngrx-store/your-home/your-home.actions';

import { SvcService } from 'src/app/modules/core/services/svc.service';

import { displayError } from 'src/app/modules/shared/utilities/notifications.utils';

@Injectable()
export class ServiceRequestsEffects {
	constructor(private actions$: Actions, private svcService: SvcService) { }

	// Retrieves Service Request for Service Request page.
	loadServiceRequests$ = createEffect(() => this.actions$.pipe(
		ofType(YourHomeActions.loadHomeDetailsSuccess),
		switchMap(({ homeDetails }) => this.svcService.getServiceRequests(homeDetails.lot.id).pipe(
			map((serviceRequests) => ServiceRequestActions.loadServiceRequestsSuccess({ serviceRequests })),
			catchError((err) => of(
				ServiceRequestActions.loadServiceRequestsFailure({
					error: err
				})
			))
		))
	));

	loadServiceRequestsFailure$ = createEffect(() => this.actions$.pipe(
		ofType(ServiceRequestActions.loadServiceRequestsFailure),
		tap(err => {
			displayError(err.error);
		})
	),
	{ dispatch: false }
	);

	postServiceRequest$ = createEffect(() => this.actions$.pipe(
		ofType(ServiceRequestActions.postServiceRequest),
		switchMap(({ serviceRequest }) => this.svcService.postServiceRequest(serviceRequest).pipe(
			map((serviceRequest) => ServiceRequestActions.postServiceRequestSuccess({ serviceRequest })),
			catchError((err) => of(
				ServiceRequestActions.postServiceRequestFailure({
					error: err
				})
			))
		))
	));

	postServiceRequestFailure$ = createEffect(() => this.actions$.pipe(
		ofType(ServiceRequestActions.postServiceRequestFailure),
		tap(err => {
			displayError(err.error);
		})
	),
	{ dispatch: false }
	);
}