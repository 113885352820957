import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';
import { RootFacade } from 'src/app/modules/ngrx-store/facade';
import { YourHomeFacade } from 'src/app/modules/ngrx-store/your-home/your-home.facade';

import { NAVIGATION_ROUTES } from 'src/app/modules/shared/constants/route-paths';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

const NAVIGATION_LABEL = {
	Dashboard: 'Dashboard',
	YourHome: 'Your Home',
	ConstructionProgress: 'Construction Progress',
	ServiceRequests: 'Service Requests',
	FinancialServices: 'Financial Services',
	Resources: 'Resources'
}

type NavigationItem = {
	customClass: string;
	svg: string; // If an item has an SVG, it will not have a mat-icon
	icon: string; // vice-versa
	label: string;
	routerLink: string;
}

@Component({
	selector: 'cp-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends UnsubscribeOnDestroy implements OnInit {
	closeOfEscrow$ = this.yourHomeFacade.closeOfEscrow$;
	homeDetails$ = this.yourHomeFacade.homeDetails$;
	preCloseOrPostCloseWithUpdates$ = this.rootFacade.preCloseOrPostCloseWithUpdates$;
	userInfo$ = this.appFacade.userInfo$;

	routes = NAVIGATION_ROUTES;
	navigationItems: NavigationItem[] = [
		{
			customClass: '',
			icon: 'dashboard',
			svg: '',
			label: NAVIGATION_LABEL.Dashboard,
			routerLink: NAVIGATION_ROUTES.Dashboard
		},
		{
			customClass: '',
			icon: '',
			svg: 'nav_your_home',
			label: NAVIGATION_LABEL.YourHome,
			routerLink: NAVIGATION_ROUTES.YourHome
		},
		{
			customClass: '',
			icon: '',
			svg: 'nav_financial_services',
			label: NAVIGATION_LABEL.FinancialServices,
			routerLink: NAVIGATION_ROUTES.FinancialServices
		},
		{
			customClass: '',
			icon: 'full_coverage',
			svg: '',
			label: NAVIGATION_LABEL.Resources,
			routerLink: NAVIGATION_ROUTES.Resources
		},
	];

	constructor(
		private router: Router,
		private appFacade: AppFacade,
		private rootFacade: RootFacade,
		private yourHomeFacade: YourHomeFacade) {
		super();
	}

	isRouteActive(route: string): boolean {
		if (route === 'MORE') {
			return this.navigationItems.slice(0, 4).every(navigationItem => !this.router.routerState.snapshot.url.includes(navigationItem.routerLink));
		}

		return this.router.routerState.snapshot.url.includes(route);
	}

	ngOnInit(): void {
		this.closeOfEscrow$.pipe(
			this.takeUntilDestroyed()
		).subscribe(coeDate => {
			// Add Service Requests if post closeOfEscrow
			const serviceRequestIndex = this.navigationItems.findIndex(i => i.label === NAVIGATION_LABEL.ServiceRequests);
			if (coeDate && serviceRequestIndex === -1) {
				const financialServicesIndex = this.navigationItems.findIndex(i => i.label === NAVIGATION_LABEL.FinancialServices);
				this.navigationItems.splice(financialServicesIndex, 0,
					{
						customClass: '',
						icon: 'construction',
						svg: '',
						label: NAVIGATION_LABEL.ServiceRequests,
						routerLink: NAVIGATION_ROUTES.ServiceRequests
					},
				);
			} else if (!coeDate && serviceRequestIndex >= 0) {
				this.navigationItems.splice(serviceRequestIndex, 1);
			}
		});

		this.preCloseOrPostCloseWithUpdates$.pipe(
			this.takeUntilDestroyed()
		).subscribe((showConstructionProgress) => {
			// Add Construction Progress if Updates Exists and not more than a year past closeOfEscrow
			const constructionProgressIndex = this.navigationItems.findIndex(i => i.label === NAVIGATION_LABEL.ConstructionProgress);

			if (showConstructionProgress && constructionProgressIndex === -1) {
				const yourHomeIndex = this.navigationItems.findIndex(i => i.label === NAVIGATION_LABEL.YourHome);
				this.navigationItems.splice(yourHomeIndex + 1, 0,
					{
						customClass: '',
						icon: 'foundation',
						svg: '',
						label: NAVIGATION_LABEL.ConstructionProgress,
						routerLink: NAVIGATION_ROUTES.ConstructionProgress
					},
				);
			} else if (!showConstructionProgress && constructionProgressIndex >= 0) {
				this.navigationItems.splice(constructionProgressIndex, 1);
			}
		});
	}
}