import { createAction, props } from '@ngrx/store';

import { ServiceRequestPost, ServiceRequestResponse } from 'src/app/modules/core/models/svc.model';

const source = '[ServiceRequests]';

/*
 * loadServiceRequests
 */
export const loadServiceRequests = createAction(
	`${source} loadServiceRequests`,
	props<{ lotId: number }>()
);

export const loadServiceRequestsFailure = createAction(
	`${source} loadServiceRequestsFailure`,
	props<{ error: Error }>()
);

export const loadServiceRequestsSuccess = createAction(
	`${source} loadServiceRequestsSuccess`,
	props<{ serviceRequests: ServiceRequestResponse[] }>()
);

/*
 * postServiceRequest
 */
export const postServiceRequest = createAction(
	`${source} postServiceRequest`,
	props<{ serviceRequest: ServiceRequestPost }>()
);

export const postServiceRequestFailure = createAction(
	`${source} postServiceRequestFailure`,
	props<{ error: Error }>()
);

export const postServiceRequestSuccess = createAction(
	`${source} postServiceRequestSuccess`,
	props<{ serviceRequest: ServiceRequestResponse }>()
);