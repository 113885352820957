<div *ngIf="!showFullscreenMedia; else fullscreenMedia" class="cp-lightbox">
	<!-- Header -->
	<div class="cp-lightbox-header" mat-dialog-title>
		<span *cpIfViewSize="['mobile', 'tablet']" class="cp-media-count">{{mediaCountText}}</span>
		<button mat-icon-button class="cp-lightbox-close" aria-label="close-modal" disableRipple (click)="closeDialog()" data-testid="close-button">
			<mat-icon fontSet="material-symbols-outlined">close</mat-icon>
		</button>
	</div>

	<!-- Carousel -->
	<div class="cp-lightbox-carousel">
		<div class="cp-carousel-item">
			<div *ngIf="selectedMedia"
				class="cp-carousel-image"
				(click)="toggleFullscreenMedia(true)"
				(keypress)="keyPressHandler($event, toggleFullscreenMedia(true))"
				(touchstart)="handleTouchStart($event)"
				(touchend)="handleTouchEnd($event)"
				role="dialog"
				data-test-id="lightbox-media">
				<cp-cloudinary-media
					*ngIf="selectedMedia.cldPublicId && selectedMedia.fileExtension"
					[publicId]="selectedMedia.cldPublicId"
					[fileExtension]="selectedMedia.fileExtension"
					[isClickable]="true"
					[showControls]="true">
				</cp-cloudinary-media>
			</div>
			<div *ngIf="media.length > 1"
				class="cp-carousel-button-container"
				(click)="toggleFullscreenMedia(true)"
				(keypress)="keyPressHandler($event, toggleFullscreenMedia(true))"
				role="dialog">
				<button mat-icon-button class="cp-carousel-button" (click)="changeSelectedMedia(this.selectedMediaIndex - 1); $event.stopPropagation()" data-testid="prev-media-button">
					<mat-icon fontSet="material-symbols-outlined">chevron_left</mat-icon>
				</button>
				<button mat-icon-button class="cp-carousel-button" (click)="changeSelectedMedia(this.selectedMediaIndex + 1); $event.stopPropagation()" data-testid="next-media-button">
					<mat-icon fontSet="material-symbols-outlined">chevron_right</mat-icon>
				</button>
			</div>
			<span *ngIf="selectedMedia?.caption" class="cp-media-caption">{{selectedMedia?.caption}}</span>
		</div>
		<span *cpIfViewSize="'desktop'" class="cp-media-count">{{mediaCountText}}</span>
	</div>

	<!-- Footer and Thumbnails -->
	<div class="cp-lightbox-footer">
		<div class="cp-lightbox-thumbnails">
			<ng-container *ngIf="media.length > 9">
				<button *cpIfViewSize="'desktop'" mat-icon-button class="cp-thumbnail-scroll-button" (click)="scrollThumbnails('left')" data-testid="scroll-thumbnail-left">
					<mat-icon fontSet="material-symbols-outlined">chevron_left</mat-icon>
				</button>
			</ng-container>
			<div #thumbnailContainer class="cp-thumbnail-container">
				<button *ngFor="let item of media; let i = index"
					class="cp-thumbnail-button"
					[ngClass]="{'cp-thumbnail-selected' : isActiveMedia(i)}"
					(click)="changeSelectedMedia(i)"
					(keypress)="keyPressHandler($event, toggleFullscreenMedia(true))">
					<div
						[id]="'thumbnail-' + i"
						class="cp-thumbnail-image"
						(keypress)="keyPressHandler($event, toggleFullscreenMedia(true))"
						role="dialog"
						data-test-id="lightbox-media">
						<cp-cloudinary-media
							*ngIf="item.cldPublicId && item.fileExtension"
							[publicId]="item.cldPublicId"
							[fileExtension]="item.fileExtension"
							[disablePlayback]="true"
							[isClickable]="true">
						</cp-cloudinary-media>
					</div>
				</button>
			</div>
			<ng-container *ngIf="media.length > 9">
				<button *cpIfViewSize="'desktop'" mat-icon-button class="cp-thumbnail-scroll-button" (click)="scrollThumbnails('right')" data-testid="scroll-thumbnail-right">
					<mat-icon fontSet="material-symbols-outlined">chevron_right</mat-icon>
				</button>
			</ng-container>
		</div>
		<span class="cp-lightbox-disclaimer">
			Photos are representative of construction progress at the time of the photo and do not necessarily show final construction.
		</span>
	</div>
</div>

<ng-template #fullscreenMedia>
	<div *ngIf="selectedMedia"
		class="cp-lightbox"
		(click)="toggleFullscreenMedia(false)"
		(keypress)="keyPressHandler($event, toggleFullscreenMedia(false))"
		role="dialog"
		data-test-id="lightbox-media-fullscreen">
		<cp-cloudinary-media
			*ngIf="selectedMedia.cldPublicId && selectedMedia.fileExtension"
			[publicId]="selectedMedia.cldPublicId"
			[fileExtension]="selectedMedia.fileExtension">
		</cp-cloudinary-media>
	</div>
</ng-template>