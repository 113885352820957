<div class="cp-footer">
	<div class="cp-footer-content">
		<div class="cp-footer-content-text">
			<span>COPYRIGHT &copy; {{currentYear}} PULTEGROUP, INC.</span>
			<div *cpIfViewSize="'desktop'" class="cp-footer-divider"></div>
			<a [href]="privacyPolicyUrl" target="_blank">PRIVACY POLICY</a>
			<div *cpIfViewSize="'desktop'" class="cp-footer-divider"></div>
			<a [href]="termsOfUseUrl" target="_blank">TERMS OF USE</a>
		</div>
		<div class="cp-footer-content-logos">
			<mat-icon [svgIcon]="'footer_equal_housing'" aria-label="Equal Housing"></mat-icon>
			<a href="https://allyant.com" target="_blank">
				<mat-icon [svgIcon]="'footer_allyant'" aria-label="Reviewed by Allyant for accessibility"></mat-icon>
			</a>
		</div>
	</div>
</div>
<div *ngIf="(disclaimers$ | async)">
	<p *ngFor="let disclaimer of disclaimers$ | async; let i = index" class="cp-footer-disclaimer">{{i + 1}} - {{disclaimer}}</p>
</div>