<div class="cp-header" [ngClass]="{'cp-header-american-west': brand?.name === 'americanWest', 'cp-header-john-wieland': brand?.name === 'johnWieland'}">
	<!-- Menu Icon for Tablet and Mobile experiences -->
	<ng-container *ngIf="isMyAccountEnabled">
		<div *cpIfViewSize="['tablet', 'mobile']" class="cp-menu">
			<button mat-icon-button
				class="cp-menu-icon-button"
				(click)="hamburgerClicked.emit($event)"
				aria-label="menu-icon"
				data-testid="menu-icon-button">
				<mat-icon fontSet="material-symbols-outlined" class="cp-menu-icon">menu</mat-icon>
			</button>
		</div>
	</ng-container>
	<!-- Holds Brand Image and Address -->
	<div class="cp-brand">
		<!-- Brand Image -->
		<img class="cp-brand-img" [src]="logoSrc" alt="Brand Logo"/>
	</div>
	
	<!-- Links for desktop experience -->
	<ng-container *ngIf="isMyAccountEnabled">
		<div *cpIfViewSize="'desktop'">
			<div class="cp-header-info" [matMenuTriggerFor]="myAccountButton">
				<a>{{ (userInfo$ | async)?.firstName + " " + (userInfo$ | async)?.lastName }}</a>
				<mat-icon fontSet="material-symbols-outlined" class="cp-carrot-icon">keyboard_arrow_down</mat-icon>
			</div>

			<mat-menu #myAccountButton="matMenu"
				yPosition="below" 
				xPosition="before"
				class="cp-header-mat-menu">
				<a mat-button [href]="myAccountUrl" target="_blank" disableRipple>
					My Account
					<mat-icon fontSet="material-symbols-outlined" iconPositionEnd>open_in_new</mat-icon>
				</a>
			</mat-menu>
		</div>
	</ng-container>
</div>
